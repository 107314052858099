<template>
    <div class="self-start">
        <p class="font-bold text-base border-b pb-2 mb-4">
            Kundreskontra
        </p>
        <p v-if="!ledgerData" class="my-10 text-center">
            Ingen kundreskontra
        </p>
        <div v-for="ledger in ledgerData" class="flex justify-start mb-4" :key="ledger.date">
            <div class="w-10 h-10 flex justify-center items-center text-white mr-3 bg-green-400 rounded-full">
                <i class="fa-regular fa-dollar-sign fa-lg text-base" style="color: hsl(0, 0%, 99%);" />
                <!-- <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path d="M12 14l9-5-9-5-9 5 9 5z" />
                    <path d="M12 14l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14z" />
                    <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M12 14l9-5-9-5-9 5 9 5zm0 0l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14zm-4 6v-7.5l4-2.222"
                    />
                </svg> -->
            </div>
            <div class="flex-col flex-1">
                <div class="flex justify-between">
                    <p class="font-light">{{ ledger.type }}</p>
                    <p>{{ ledger.amount | swedishNumberFormat }} SEK</p>
                </div>
                <p class="text-gray-400 text-xs">{{ ledgerTimeFormater(ledger.date) }}</p>
            </div>
        </div>
        <div class="flex justify-between py-3 border-t">
            <p>Balans:</p>
            <p>{{ amountLeft() | swedishNumberFormat }} SEK</p>
        </div>
    </div>
</template>
<script>
import moment from "moment";

export default {
    created() {
        console.log("🚀 - file: Ledger.vue:42 - created - this.ledgerData:", this.ledgerData);
    },
    props: {
        ledgerData: {
            type: Array,
            default: () => [],
        },
    },

    methods: {
        ledgerTimeFormater(time) {
            return moment(time).format("YYYY-MM-DD");
        },

        amountLeft() {
            let balance = 0;
            this.ledgerData.forEach(item => {
                balance += item.amount;
            });
            return balance;
        },
    },
};
</script>
